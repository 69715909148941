module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-177837659-1","head":false,"respectDNT":true,"anonymize":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-page-transitions/gatsby-browser.js'),
      options: {"plugins":[],"transitionTime":500},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"portfolio-v2","short_name":"portfolio","start_url":"/","background_color":"#F8F6F1","theme_color":"#AF4448","theme_color_in_head":false,"display":"minimal-ui","icon":"src/images/portfolio-logo.png","icon_options":{"purpose":"any maskable"},"crossOrigin":"anonymous","legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"0271f6affc729c593edf67b82060dcfd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mailgo/gatsby-browser.js'),
      options: {"plugins":[],"mailgoConfig":{"showFooter":false}},
    }]
